import * as React from 'react';

import { Box, Button, Heading, Link, Text, VStack } from '@chakra-ui/react';

import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Base/Layout';
import { SEO } from '../components/Base/Seo';

const NotFoundPage: FunctionComponent = () => {
	return (
		<Layout forceWhiteHeaderTheme>
			<Helmet>
				<meta
					name='robots'
					content='noindex, nofollow'
				/>
			</Helmet>
			<Box>
				<Box
					bgImage='/herobackground404.webp'
					w='100%'
					minH='700px'
					h='700px'
					bgRepeat='no-repeat'
					bgSize='cover'
					bgPosition='center'>
					<div className="overlay" />
					<VStack
						h='inherit'
						justifyContent='center'
						gap='16px'
						color='white'
						zIndex='2'
						position='relative'>
						<Heading
							as='h1'
							fontSize='6.77rem!important'>
							404
						</Heading>
						<Text fontSize='20px'>
							Sorry, the page you were looking for was not found :(
						</Text>

						<Link href='/'>
							<Button
								variant='outlineWhite'
								colorScheme='white'
								color='white'>
								BACK TO HOME PAGE
							</Button>
						</Link>
					</VStack>
				</Box>
			</Box>
		</Layout>
	);
};

export const Head = () => (
	<SEO
		title='DoctorMedica.co'
		description='Reach DoctorMedica and let us help you find the best Botox suppliers in the US and around the globe. Genuine products only. Price match guaranteed.'
	/>
);

export default NotFoundPage;
